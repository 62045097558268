import { FC, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Loader } from '@liveconnect/components'

import { CometChatConversationListWithMessages } from '../../../vendor/Cometchat'
import { Main } from '../../components/Main'
import useChat from '../../core/chat/useChat'
import EmptyState from '../../components/EmptyState'
import useMember from '../../core/member/useMember'
import { DEFAULT_LANG } from '../../i18n/config'
import useGTM from '../../utils/useGTM'

import './styles.scss'

const ChatScreen: FC = () => {
  const { t } = useTranslation()
  const { member } = useMember()
  const { userId } = useParams()
  const { isLoaded, hasErrors, setHasNewMessages, initChat } = useChat()
  const { pushDataLayer } = useGTM()

  const userLang = useMemo(() => {
    const lang = member?.isoCode ?? DEFAULT_LANG
    return lang.split('-')[0]
  }, [member])

  const handleMessageDelivered = () => {
    pushDataLayer({
      category: 'interaction',
      event: 'message_sent_community',
      action: 'message_sent',
    })
  }

  useEffect(() => {
    setHasNewMessages(false)
  }, [isLoaded])

  useEffect(() => {
    initChat()
    return () => setHasNewMessages(false)
  }, [])

  return (
    <Main ariaLabelledby="chat-title" className="d-flex flex-column">
      <h1 id="chat-title" className="h3 mb-4">
        {t('chat.title')}
      </h1>

      <div className="lc-chat">
        {(isLoaded &&
          ((hasErrors && <EmptyState text={t('chat.error.noContacts')} />) || (
            <CometChatConversationListWithMessages
              chatWithUser={userId}
              lang={userLang}
              onMessageDelivered={handleMessageDelivered}
            />
          ))) || <Loader />}
      </div>
    </Main>
  )
}

export default ChatScreen
