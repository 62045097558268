import { FC } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Header, LogoImage } from '@liveconnect/components'
import { NotificationsLib } from '@liveconnect/notifications'

import useUi from '../../core/ui/useUi'
import { ApplicationLogo } from './ApplicationLogo'
import useMember from '../../core/member/useMember'
import { useCustomRouter } from '../../utils/useCustomRouter'
import useChat from '../../core/chat/useChat'

import logoBlack from '../../../assets/images/logoIfemaBlack.svg'
import useSettings from '../../core/settings/useSettings'
import { IsoCode } from '../../core/member/types'
import useTenantConfig from '../../core/tenantConfig/useTenantConfig'

import './styles.scss'

const HeaderApp: FC = () => {
  const { tenantId } = useParams()
  const { t } = useTranslation()
  const { basePath } = useCustomRouter()
  const {
    toggleShowPanelRight,
    isPanelVisibleRight,
    toggleShowPanelLeft,
    isPanelVisibleLeft,
  } = useUi()
  const { member } = useMember()
  const { hasNewMessages } = useChat()
  const { settings } = useSettings()
  const navigate = useNavigate()
  const { tenantConfig } = useTenantConfig()

  return (
    <>
      <Header className="header">
        <Header.Left>
          <Header.BurgerButton
            active={isPanelVisibleLeft}
            title={t('header.burgerMenu')}
            onClick={toggleShowPanelLeft}
          />

          <Header.Logo>
            <Link to={basePath} className="navbar-brand">
              <ApplicationLogo />
            </Link>
          </Header.Logo>

          <Header.Logo
            className="d-flex d-sm-none"
            responsiveStyles="d-flex d-sm-none"
          >
            <LogoImage src={logoBlack} size="sm" alt="ifemaLogo" />
          </Header.Logo>
        </Header.Left>

        <Header.Right>
          {tenantConfig?.tenantLogoUrl && (
            <Header.Logo>
              <LogoImage
                src={tenantConfig.tenantLogoUrl}
                size="sm"
                alt="ifemaLogo"
                className="header__tenant-logo"
              />
            </Header.Logo>
          )}

          <Header.Menu>
            <Header.MenuItem>
              <Link
                to={`${basePath}/chat`}
                className="header__item header__item--email"
                title={t('header.messages')}
              >
                <Header.MenuIcon icon="mode_comment" dot={hasNewMessages} />
              </Link>
            </Header.MenuItem>
            <NotificationsLib
              token={
                localStorage.getItem(
                  `ifema_communities_web_access_token${tenantId}`
                ) as string
              }
              bff={settings?.bff.url as string}
              lang={member?.isoCode as IsoCode}
              onClickActivity={(url) => (window.location.href = url)}
              baseImgUrl={settings?.assets.url as string}
              onClickContactRequest={() =>
                navigate(`${basePath}/contact-requests`)
              }
              onClickMeeting={(url) => (window.location.href = url)}
            />
            {member && (
              <Header.AvatarButton
                onClick={toggleShowPanelRight}
                active={isPanelVisibleRight}
                avatar={{
                  name: member.name,
                  surname: member.surname,
                  imageUrl: member.imageUrl || undefined,
                  alt: t('header.avatar'),
                }}
              />
            )}
          </Header.Menu>
        </Header.Right>
      </Header>
    </>
  )
}

export default HeaderApp
